<!--
 * @Author: lbh
 * @Date: 2022-01-10 16:13:52
 * @LastEditors: lbh
 * @LastEditTime: 2023-01-14 12:28:43
 * @Description: file content
-->
<template>
  <div class="login-box px-login-box">
    <div class="login">
      <h3>歡迎登錄官網後台</h3>
      <el-form
        ref="form"
        :model="form"
      >
        <el-input
          v-model="form.name"
          placeholder="請輸入用戶名"
        ></el-input>
        <el-input
          v-model="form.password"
          type="password"
          placeholder="請輸入密碼"
        ></el-input>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            @click="onSubmit"
            class="theme_bg_color theme_border_color"
          >登錄</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        name: "",
        password: ''
      }
    }
  },
  created () {
    this.keyupSubmit();
  },
  destroyed () {
    document.onkeydown = null
  },
  methods: {
    keyupSubmit () {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        //!this.clickState是防止用户重复点击回车
        if (_key === 13) {
          this.onSubmit();
        }
      };
    },
    onSubmit () {
      let form = this.form;
      if (form.name == 'admin' && form.password == 'cc81081168') {
        this.$store.commit('setIsLogin', true);
        this.$router.replace({
          path: "/menu"
        });
      } else {
        this.$message({
          message: '賬戶或密碼錯誤!',
          type: 'warning'
        });
      }

    }
  }
}
</script>

<style lang="less" scoped>
.login-box {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/login_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
    min-width: 300px;
    padding: 20px 20px 0;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    box-shadow: 0 2px 6px #efefef;
    h3 {
      margin-bottom: 20px;
      color: #fff;
    }
    & /deep/ .el-input {
      margin-bottom: 20px;
      input {
        background-color: rgba(255, 255, 255, 0.4);
        &::placeholder {
          color: #fff !important;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 1);
          &::placeholder {
            color: #ccc !important;
          }
        }
      }
    }
    & /deep/ .el-button {
      width: 100%;
      background-color: rgba(64, 158, 255, 0.4);
      border-color: rgba(64, 158, 255, 0.4);
      &:hover {
        background-color: rgba(64, 158, 255, 1);
        border-color: rgba(64, 158, 255, 1);
      }
    }
  }
}

@media screen and(max-width:1000px) {
  .px-login-box {
    .login {
      width: 40%;
      border-radius: 10px;
      padding: 10px;
    }
  }
}

@media screen and (max-width: 500px) {
  .px-login-box {
    .login {
      width: 80%;
      padding: 10px;
      border-radius: 10px;
    }
  }
}
</style>